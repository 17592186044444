
















import { Component, Vue } from 'vue-property-decorator'
import PageLayout from '@/components/common/page-layout/PageLayout.vue'
import ContentArea from '@/components/common/ContentArea.vue'
import Outcome from '@/components/Outcome.vue'
import { LocaleMessages } from 'vue-i18n'

interface IText {
  title: LocaleMessages | string
  description: LocaleMessages | string
}

@Component({
  components: {
    PageLayout,
    ContentArea,
    Outcome
  }
})

export default class Positive extends Vue {
  private outcomeContent: IText = {
    title: '',
    description: ''
  }

  private created (): void {
    // const result = localStorage.getItem('testResult') as string
    // this.$store.dispatch('walkthrough/completeTest', result)
    // this.$store.dispatch('auth/deleteResultData')
    this.outcomeContent.title = this.$t('outcome.positive.title')
    this.outcomeContent.description = this.$t('outcome.positive.description')
  }
}
